import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../../../core/services/message.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AdxadValidators } from '../../../../core/validators';
import { filter } from 'rxjs/operators';
import { AdxadLabelTabIconStatus, Dict, Dicts, LazyLoadDict } from '../../../../core/interface';
import { ExchangeService } from '../../exchange.service';
import { DspService } from '../../../../core/services/dsp.service';
import { getControlChanges } from '../../../../core/helpers/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-dsp-form',
  templateUrl: './dsp-form.component.html',
  styleUrls: ['./dsp-form.component.scss']
})
export class DspFormComponent implements OnInit {
  form: FormGroup;
  isLoading = false;
  isLoadingSubmit = false;
  isNewDsp = true;

  dicts = {
    costModel: {
      data: [
        { id: 'cpc', value: 'cpc' },
        { id: 'cpm', value: 'cpm' }
      ]
    },
    advType: {
      data: [
        { id: 'pops', value: 'Popunders' },
        { id: 'push', value: 'Push' }
      ]
    },
    responseType: {
      data: [
        { id: 'plain', value: 'plain' },
        { id: 'exads', value: 'exads' }
      ]
    },
    redirectType: {
      data: [
        { id: '1', value: '302' },
        { id: '2', value: 'meta' },
        { id: '3', value: 'js' }
      ]
    },
    typeOfZone: {
      data: [
        { id: '1', value: 'Original' },
        { id: '2', value: 'Hash' },
        { id: '3', value: 'Hash with DSP' }
      ]
    }
  } as Dicts;

  private cursorPosition = 0;
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private alerts: AdxadAlerts,
    public config: AdxadModalConfig,
    private modalRef: AdxadModalRef,
    private exchangeService: ExchangeService,
    private dspService: DspService
  ) {}

  ngOnInit(): void {
    if (this.config.data && this.config.data.id) {
      this.isNewDsp = false;
    }

    this.createForm();
    this.getChanges();

    if (this.isNewDsp) {
      this.loadData();
    } else {
      this.loadDsp(this.config.data.id);
    }
  }

  /**
   * @return tab valid status
   */
  public getTabStatus(): AdxadLabelTabIconStatus {
    return this.form.valid ? 'success' : 'invalid';
  }

  get domainsControl(): FormControl {
    return this.form.get('domains') as FormControl;
  }

  /**
   * Load dsp form dicts
   * If error -> close modal
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    this.dspService
      .getExchangeDspUrlPlaceholders(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: LazyLoadDict) => {
          this.isLoading = false;
          this.dicts.urlPlaceholders = result;
          this.form.markAllAsTouched();
        },
        error: () => {
          this.close();
        }
      });
  }

  /**
   * Load dsp data
   *
   * @param id - dsp id
   */
  loadDsp(id: string): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.exchangeService
      .getDspForm({ id: id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.loadData();

          if (result.data.geoRevshare && result.data.geoRevshare.length > 0) {
            result.data.geoRevshare.forEach(control => {
              this.revShares.push(
                this.fb.group({
                  geo: [control.geo, [Validators.required]],
                  revenue: [control.revenue, [Validators.min(0), Validators.max(100)]]
                })
              );
            });
          }

          this.form.patchValue(result.data);
          this.form.updateValueAndValidity();

          this.domainsControl.markAsTouched();
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  get revShares(): FormArray {
    return this.form.get('geoRevshare') as FormArray;
  }

  /**
   * Create dsp form
   */
  createForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3), AdxadValidators.en]],
      extId: ['', [Validators.required]],
      url: ['', [Validators.required, AdxadValidators.clickUrl]],
      rpsLimit: [0, [Validators.min(0)]],
      costModel: ['cpc'],
      advType: ['pops'],
      isOpenRtb: [false],
      isWinNotification: [false],
      testMode: [true],
      responseType: ['json-parser', [Validators.required]],
      domains: [[], [AdxadValidators.clickUrl]],
      redirectType: ['3'],
      maxClicksPerIp: [0, [Validators.max(20), AdxadValidators.integer]],
      filters: this.fb.group({
        sspFilter: this.fb.group({
          mode: [true],
          value: [[]]
        }),
        osFilter: this.fb.group({
          mode: [true],
          value: [[]]
        }),
        browsersFilter: this.fb.group({
          mode: [true],
          value: [[]]
        }),
        geoFilter: this.fb.group({
          mode: [true],
          value: [[]]
        }),
        trafficCategoryFilter: this.fb.group({
          mode: [true],
          value: [[]]
        }),
        segmentFilter: this.fb.group({
          mode: [true],
          value: [[]]
        }),
        campaignFilter: this.fb.group({
          mode: [false],
          value: [[]]
        }),
        siteIdFilter: this.fb.group({
          mode: [false],
          value: [[]]
        })
      }),
      geoRevshare: this.fb.array([]),
      decoder: this.fb.group({
        format: ['json'],
        path: this.fb.group({
          bid: [''],
          bannerUrl: [''],
          clickUrl: [''],
          nurl: [''],
          pushTitle: [''],
          pushDescription: [''],
          pushIconUrl: [''],
          pushImageUrl: ['']
        })
      }),
      commission: this.fb.control(100, [Validators.required, Validators.min(0), Validators.max(300)]),
      isInternal: [false],
      typeOfZone: ['2']
    });

    if (!this.isNewDsp) {
      this.form.addControl('id', this.fb.control(this.config.data.id));
    }
  }

  getChanges(): void {
    const nameControl = this.form.get('name');

    getControlChanges(this.form, 'name')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => nameControl.dirty || nameControl.touched)
      )
      .subscribe({
        next: value => {
          const val = value.toLowerCase().replace(/\s+/g, '-');
          this.form.get('extId').patchValue(val, { emitEvent: false });
        }
      });
  }

  /**
   * Sets the value of 'responseType' control in the form based on the provided boolean value.
   * If the value is true, it sets the value of 'responseType' control to 'plain'.
   * If the value is false, it clears the value of 'responseType' control.
   * It then updates the validity of the control.
   *
   * @param {boolean} e - The boolean value indicating whether to set 'responseType' or clear it.
   *
   * @return {void}
   */
  isOpenRtbChange(e: boolean): void {
    const formControls = {
      responseTypeControl: this.form.get('responseType'),
      isInternalControl: this.form.get('isInternal'),
      isTitlePathControl: this.form.get('decoder.path.pushTitle'),
      isDescriptionPathControl: this.form.get('decoder.path.pushDescription'),
      isIconPathControl: this.form.get('decoder.path.pushIconUrl'),
      isImageUrlPathControl: this.form.get('decoder.path.pushImageUrl'),
      isBidPathControl: this.form.get('decoder.path.bid'),
      isClickUrlPathControl: this.form.get('decoder.path.clickUrl')
    };

    const setControlValues = (values: { [key: string]: any }) => {
      Object.entries(values).forEach(([controlName, value]) => {
        formControls[controlName].setValue(value);
        formControls[controlName].updateValueAndValidity();
      });
    };

    if (e) {
      setControlValues({
        responseTypeControl: 'plain',
        isInternalControl: false,
        isTitlePathControl: '',
        isDescriptionPathControl: '',
        isIconPathControl: '',
        isImageUrlPathControl: '',
        isBidPathControl: '',
        isClickUrlPathControl: ''
      });
    } else {
      formControls.responseTypeControl.updateValueAndValidity();
    }
  }

  isInternalChange(e: boolean) {
    const formControls = {
      responseTypeControl: this.form.get('responseType'),
      isOpenRtbControl: this.form.get('isOpenRtb'),
      isBidPathControl: this.form.get('decoder.path.bid'),
      isClickUrlPathControl: this.form.get('decoder.path.clickUrl'),
      isTitlePathControl: this.form.get('decoder.path.pushTitle'),
      isDescriptionPathControl: this.form.get('decoder.path.pushDescription'),
      isIconPathControl: this.form.get('decoder.path.pushIconUrl'),
      isImageUrlPathControl: this.form.get('decoder.path.pushImageUrl')
    };

    const setControlValues = (values: { [key: string]: any }) => {
      Object.entries(values).forEach(([controlName, value]) => {
        formControls[controlName].setValue(value);
        formControls[controlName].updateValueAndValidity();
      });
    };

    const pushValues = {
      isTitlePathControl: 'title',
      isDescriptionPathControl: 'description',
      isIconPathControl: 'iconUrl',
      isImageUrlPathControl: 'mediaUrl'
    };

    const emptyValues = {
      isTitlePathControl: '',
      isDescriptionPathControl: '',
      isIconPathControl: '',
      isImageUrlPathControl: ''
    };

    const defaultValues = e
      ? {
          responseTypeControl: 'json-parser',
          isOpenRtbControl: false,
          isBidPathControl: 'price',
          isClickUrlPathControl: 'clickUrl'
        }
      : {
          responseTypeControl: '',
          isBidPathControl: '',
          isClickUrlPathControl: ''
        };

    setControlValues(defaultValues);

    if (e) {
      const advTypeValue = this.form.get('advType').value;

      if (advTypeValue === 'push') {
        setControlValues(pushValues);
      }

      getControlChanges(this.form, 'advType')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => {
          if (e) {
            setControlValues(value === 'push' ? pushValues : emptyValues);
          }
        });
    } else {
      const advTypeValue = this.form.get('advType').value;
      setControlValues(advTypeValue === 'push' ? emptyValues : defaultValues);
    }
  }

  /**
   * Set cursor position on blur field
   *
   * @param {FocusEvent} e
   */
  setCursorPosition(e: FocusEvent): void {
    this.cursorPosition = e.target['selectionStart'];
  }

  /**
   * Add placeholders in url
   * or replace all same holders
   *
   * @param {Dict} placeholder
   */
  toggleUrlPlaceholders(placeholder: Dict): void {
    const control = this.form.get('url');
    let code = control.value;

    if (code.indexOf(placeholder.id) !== -1) {
      const re = new RegExp(placeholder.id, 'g');
      code = code.replace(re, '');
    } else {
      code = code.slice(0, this.cursorPosition) + placeholder.id + code.slice(this.cursorPosition);
    }
    control.setValue(code);
  }

  /**
   * Create/edit dsp
   */
  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this.isLoadingSubmit = true;
    const data = this.form.value;

    const method = this.isNewDsp ? this.exchangeService.addDsp(data) : this.exchangeService.editDsp(data);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: response => {
        this.isLoadingSubmit = false;
        if (response.status === 'OK') {
          const message = 'DSP successfully ' + (this.isNewDsp ? 'created' : 'changed');
          this.messageService.add('reload-exchange-dsps-grid', { submit: true });
          this.alerts.success(message, 3000);
          this.close(true);
        }
      },
      error: () => (this.isLoadingSubmit = false)
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
